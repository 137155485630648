import React, { useState, useEffect } from "react";

const QuestionList = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [questionsPerPage] = useState(20); // Display 20 questions per page
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredQuestions, setFilteredQuestions] = useState(data);

  useEffect(() => {
    if (searchTerm === "") {
      setFilteredQuestions(data);
    } else {
      setFilteredQuestions(
        data.filter((q) =>
          q.question.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
  }, [searchTerm, data]);

  const totalPages = Math.ceil(filteredQuestions?.length / questionsPerPage);
  const currentQuestions = filteredQuestions?.slice(
    (currentPage - 1) * questionsPerPage,
    currentPage * questionsPerPage
  );

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleCheck = (qcode, question) => {
    const isSelected = selectedQuestions?.some((q) => q.qcode === qcode);
    if (isSelected) {
      setSelectedQuestions((prevSelected) =>
        prevSelected?.filter((q) => q.qcode !== qcode)
      );
    } else {
      setSelectedQuestions((prevSelected) => [
        ...prevSelected,
        { qcode, question },
      ]);
    }
  };

  const handleRemove = (qcode) => {
    setSelectedQuestions((prevSelected) =>
      prevSelected?.filter((q) => q.qcode !== qcode)
    );
  };

  return (
    <div>
      <h1>Question List</h1>

      {/* Search input */}
      <input
        type="text"
        placeholder="Search question..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      <div>
        {currentQuestions?.map((question) => (
          <div
            key={question.qcode}
            style={{
              border: "1px solid black",
              marginBottom: "10px",
              padding: "10px",
            }}
          >
            <h4>{question.question}</h4>
            <div>
              <label>
                <input
                  type="checkbox"
                  checked={selectedQuestions?.some(
                    (q) => q.qcode === question.qcode
                  )}
                  onChange={() =>
                    handleCheck(question?.qcode, question?.question)
                  }
                />
                Select this question
              </label>
            </div>
            <p>Options:</p>
            <ul>
              <li>{question.option1}</li>
              <li>{question.option2}</li>
              <li>{question.option3}</li>
              <li>{question.option4}</li>
            </ul>
          </div>
        ))}
      </div>

      {/* Pagination buttons */}
      <div>
        <button onClick={handlePreviousPage} disabled={currentPage === 1}>
          Previous
        </button>
        <span>
          {" "}
          Page {currentPage} of {totalPages}{" "}
        </span>
        <button onClick={handleNextPage} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>

      {/* Display selected questions */}
      <div>
        <h3>Selected Questions:</h3>
        <ul>
          {selectedQuestions.map(({ qcode, question }) => (
            <li key={qcode}>
              {qcode}: {question}
              <button
                onClick={() => handleRemove(qcode)}
                style={{ marginLeft: "10px", color: "red" }}
              >
                &times;
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default QuestionList;
