import React, { useEffect, useState } from "react";
import { useGlobalContext } from "../Context/GlobalContextOne";
import { PiExamFill } from "react-icons/pi";
import { MdOutlineErrorOutline } from "react-icons/md";
import { useTestContext } from "../Context/TestContext";
import Loading from "./Loading";
import { useNavigate } from "react-router-dom";
import CircularProgressBar from "./CircularProgressBar";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useUserContext } from "../Context/UserContext";

const SelectTestToDo = ({ testType, bgColor, from }) => {
  const { subject, SearchKeyWord } = useGlobalContext();
  const { user } = useUserContext();
  const [levelStats, setLevelStats] = useState({});
  const [totalAttInSet, setTotalAttInSet] = useState(0);
  const [totalCorInSet, setTotalCorInSet] = useState(0);

  const { test_loading, temp_test_data, test_error, SetStartTestData } =
    useTestContext();
  const [test_data, setTest_data] = useState(); // Initialize with temp_test_data

  const navigate = useNavigate();

  const startTest = (testDataToStartTest) => {
    SetStartTestData(testDataToStartTest);
    navigate("/TestSeries/Start-Test");
  };

  const fetchTestInfo = async () => {
    if (from === "search") {
      try {
        const response = await fetch(
          `https://railwaymcq.com/railwaymcq/RailPariksha/Searched_Mcq_Sets.php?user_id=${user?.id}&search_keyword=${SearchKeyWord}`
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setTest_data(data); // Update test_data only when from is "search"
      } catch (error) {
        console.error("Error fetching SUBJECT_MASTER info:", error);
      }
    }
  };

  useEffect(() => {
    if (from === "search") {
      fetchTestInfo();
    } else setTest_data(temp_test_data);
  }, [SearchKeyWord, from]);

  useEffect(() => {
    if (test_data) {
      const levelState = {};

      Object.entries(test_data).forEach(([level, sets]) => {
        let levelAttempted = 0;
        let levelCorrect = 0;

        levelState[level] = {
          totalAttempted: 0,
          totalCorrect: 0,
          sets: {},
        };

        Object.entries(sets).forEach(([setName, questions]) => {
          let setAttempted = 0;
          let setCorrect = 0;

          questions.forEach((question) => {
            if (question.user_response.attempted) {
              setAttempted += 1;
              levelAttempted += 1;
            }
            if (question.user_response.correct) {
              setCorrect += 1;
              levelCorrect += 1;
            }
          });

          levelState[level].sets[setName] = {
            totalAttempted: setAttempted,
            totalCorrect: setCorrect,
          };
        });

        levelState[level].totalAttempted = levelAttempted;
        levelState[level].totalCorrect = levelCorrect;
      });
      setLevelStats(levelState);
    }
  }, [test_data]);

  useEffect(() => {
    setTotalAttInSet(levelStats[testType]?.totalAttempted);
    setTotalCorInSet(levelStats[testType]?.totalCorrect);
  }, [levelStats, testType]);
  console.log(test_data);
  return (
    <div className="row ">
      {test_loading ? (
        <h4>
          loading tests <Loading />
        </h4>
      ) : (
        <div>
          {test_data ? (
            <div>
              {Object.entries(test_data).map(([key, value]) => {
                let totalQuestions = 0;
                for (const set in value) {
                  totalQuestions += value[set].length;
                }
                let attPer = (totalAttInSet / totalQuestions) * 100;
                let corPer = (totalCorInSet / totalAttInSet) * 100;
                let wroPer =
                  ((totalAttInSet - totalCorInSet) / totalAttInSet) * 100;
                if (key === testType) {
                  return (
                    <div key={key}>
                      {Object.keys(value).length === 0 ? (
                        <h5 style={{ color: "red" }}>
                          <MdOutlineErrorOutline /> No Tests Found
                        </h5>
                      ) : (
                        <div>
                          <div
                            className="d-flex justify-content-around  ms-2 me-2 papaDiv"
                            style={{ maxHeight: "65px" }}
                          >
                            <div>
                              <div>
                                Total {Object.keys(value).length} Sets,&nbsp;
                              </div>
                              <div> {totalQuestions} Questions</div>
                            </div>

                            {/* ////////////////////////////////////////////////////////////////////////// */}
                            <div className="text-center">
                              <div className="d-flex justify-content-between ">
                                <div className="me-2">
                                  {/* Attempted-//////////////////////////////// */}
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                      <Tooltip id="tooltip-dashboard">
                                        You Attempted {totalAttInSet} Question,
                                        out of {totalQuestions} Questions .
                                      </Tooltip>
                                    }
                                  >
                                    <div
                                      className="d-flex align-item-center"
                                      style={{ fontSize: "15px" }}
                                    >
                                      <CircularProgressBar
                                        data={attPer}
                                        size="35px"
                                        primary="#0378cf"
                                        secondary="#e1f0ff"
                                      />
                                    </div>
                                  </OverlayTrigger>
                                  <div style={{ fontSize: "11px" }}>
                                    {totalAttInSet}
                                  </div>
                                </div>
                                <div className="">
                                  {/* correct - ///////////////////////////////////////////////// */}
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                      <Tooltip id="tooltip-dashboard">
                                        You marked {totalCorInSet} Correct out
                                        of {totalAttInSet} Attempted Questions
                                      </Tooltip>
                                    }
                                  >
                                    <div
                                      className="d-flex align-item-center"
                                      style={{ fontSize: "15px" }}
                                    >
                                      <CircularProgressBar
                                        data={corPer}
                                        size="35px"
                                        primary="#0fcf03"
                                        secondary="#e8ffe1"
                                      />
                                    </div>
                                  </OverlayTrigger>
                                  <div style={{ fontSize: "11px" }}>
                                    {totalCorInSet}
                                  </div>
                                </div>
                                <div className="ms-2">
                                  {/* Incorrect - //////////////////////////// */}
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                      <Tooltip id="tooltip-dashboard">
                                        You marked{" "}
                                        {totalAttInSet - totalCorInSet} Wrong
                                        out of {totalAttInSet}
                                        Attempted Questions
                                      </Tooltip>
                                    }
                                  >
                                    <div
                                      className="d-flex align-item-center"
                                      style={{ fontSize: "15px" }}
                                    >
                                      <CircularProgressBar
                                        data={wroPer}
                                        size="35px"
                                        primary="#ff6347"
                                        secondary="#ffe4e1"
                                      />
                                    </div>
                                  </OverlayTrigger>
                                  <div style={{ fontSize: "11px" }}>
                                    {totalAttInSet - totalCorInSet}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* ////////////////////////////////////////////////////////////////////////// */}
                            <div>keep practicing</div>
                          </div>

                          <div
                            className="row overflow-auto "
                            style={{
                              maxHeight: "450px", // Set a fixed height for the container
                              overflowY: "auto", // Enable vertical scrolling
                            }}
                          >
                            {Object.entries(value).map(
                              ([setNo, test], index) => {
                                let setUserData =
                                  levelStats[testType]?.sets[setNo];
                                let attemptedQ = setUserData?.totalAttempted;
                                let correctedQ = setUserData?.totalCorrect;
                                let attPer = (attemptedQ / test.length) * 100;
                                let corPer = (correctedQ / attemptedQ) * 100;
                                let wroPer =
                                  ((attemptedQ - correctedQ) / attemptedQ) *
                                  100;

                                return (
                                  <div
                                    key={index}
                                    className="col-md-3 mb-3"
                                    style={{
                                      cursor: "pointer",
                                    }}
                                  >
                                    <div
                                      className="card Subject underline"
                                      style={{
                                        background: `linear-gradient(to bottom, white,${bgColor})`,
                                        margin: "0px",
                                        padding: "0px",
                                        height: "100%", // Make sure the card fills its container
                                      }}
                                    >
                                      <div className="card-body m-2">
                                        <div className="d-flex position-relative justify-content-between">
                                          <div
                                            className="text-muted"
                                            style={{
                                              fontSize: "14px",
                                              marginBottom: "0",
                                              paddingTop: "0px",
                                            }}
                                          >
                                            {subject.department}
                                          </div>
                                          <div style={{ fontSize: "14px" }}>
                                            {setNo}
                                          </div>
                                        </div>
                                        {/* ////////////////////////////////////////////////////////////////////////// */}
                                        <div
                                          className="text-center"
                                          style={{
                                            margin: "0px",
                                            padding: "0px",
                                          }}
                                        >
                                          <div style={{ fontSize: "12px" }}>
                                            Set Record
                                          </div>
                                          <div
                                            className="d-flex justify-content-evenly "
                                            style={{
                                              margin: "0px",
                                              padding: "0px",
                                            }}
                                          >
                                            <div className="mt-1 pb-0">
                                              {/* Attempted-//////////////////////////////// */}
                                              <OverlayTrigger
                                                placement="bottom"
                                                overlay={
                                                  <Tooltip id="tooltip-dashboard">
                                                    You Attempted {attemptedQ}{" "}
                                                    Question, out of{" "}
                                                    {test.length} Questions .
                                                  </Tooltip>
                                                }
                                              >
                                                <div
                                                  className="d-flex align-item-center"
                                                  style={{ fontSize: "15px" }}
                                                >
                                                  <CircularProgressBar
                                                    data={attPer}
                                                    size="30px"
                                                    primary="#0378cf"
                                                    secondary="#e1f0ff"
                                                  />
                                                </div>
                                              </OverlayTrigger>
                                              <div style={{ fontSize: "11px" }}>
                                                {attemptedQ}
                                              </div>
                                            </div>
                                            <div className="mt-1 pb-0">
                                              {/* correct - ///////////////////////////////////////////////// */}
                                              <OverlayTrigger
                                                placement="bottom"
                                                overlay={
                                                  <Tooltip id="tooltip-dashboard">
                                                    You marked {correctedQ}{" "}
                                                    Correct out of {attemptedQ}{" "}
                                                    Attempted Questions
                                                  </Tooltip>
                                                }
                                              >
                                                <div
                                                  className="d-flex align-item-center"
                                                  style={{ fontSize: "15px" }}
                                                >
                                                  <CircularProgressBar
                                                    data={corPer}
                                                    size="30px"
                                                    primary="#0fcf03"
                                                    secondary="#e8ffe1"
                                                  />
                                                </div>
                                              </OverlayTrigger>
                                              <div style={{ fontSize: "11px" }}>
                                                {correctedQ}
                                              </div>
                                            </div>
                                            <div className="mt-1 pb-0">
                                              {/* Incorrect - //////////////////////////// */}
                                              <OverlayTrigger
                                                placement="bottom"
                                                overlay={
                                                  <Tooltip id="tooltip-dashboard">
                                                    You marked{" "}
                                                    {attemptedQ - correctedQ}{" "}
                                                    Wrong out of {attemptedQ}
                                                    Attempted Questions
                                                  </Tooltip>
                                                }
                                              >
                                                <div
                                                  className="d-flex align-item-center"
                                                  style={{ fontSize: "15px" }}
                                                >
                                                  <CircularProgressBar
                                                    data={wroPer}
                                                    size="30px"
                                                    primary="#ff6347"
                                                    secondary="#ffe4e1"
                                                  />
                                                </div>
                                              </OverlayTrigger>
                                              <div style={{ fontSize: "11px" }}>
                                                {attemptedQ - correctedQ}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {/* ////////////////////////////////////////////////////////////////////////// */}

                                        <div className="row">
                                          <div className="col-12 col-sm-12">
                                            <div className="container text-center">
                                              <div className="row pb-1">
                                                <ul
                                                  className="col m-1 text-start"
                                                  style={{ fontSize: "14px" }}
                                                >
                                                  <hr />
                                                  <li>
                                                    Subject - {subject.subject}
                                                  </li>
                                                  <li>
                                                    Topic -{" "}
                                                    {subject.selectedTopic}
                                                  </li>
                                                  <li>
                                                    Question - {test.length}
                                                  </li>
                                                  <li>
                                                    Language - English ,
                                                    Hindi... etc
                                                  </li>
                                                  <li>
                                                    Difficulty -{" "}
                                                    {testType === "easy"
                                                      ? "Easy"
                                                      : testType === "tough"
                                                      ? "Tough"
                                                      : testType === "moderate"
                                                      ? "Moderate"
                                                      : "Mixed"}
                                                  </li>
                                                  <li>
                                                    Quiz Time -{" "}
                                                    {`${test.length}:00`}
                                                  </li>
                                                  <hr />
                                                </ul>
                                              </div>
                                            </div>
                                            <div className="container text-center">
                                              <div className="row">
                                                <div
                                                  onClick={() =>
                                                    startTest({
                                                      set: test,
                                                      timing: test.length,
                                                    })
                                                  }
                                                  className={`col btn btn-outline-${
                                                    testType === "easy"
                                                      ? "success"
                                                      : testType === "tough"
                                                      ? "danger"
                                                      : testType === "moderate"
                                                      ? "info"
                                                      : testType === "random"
                                                      ? "warning"
                                                      : "danger"
                                                  } m-1`}
                                                >
                                                  <PiExamFill /> Start Test
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  );
                }
                return null;
              })}
            </div>
          ) : (
            <h3 style={{ color: "red" }}>Something went wrong</h3>
          )}
        </div>
      )}
    </div>
  );
};

export default SelectTestToDo;
